import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BrowseCars from '../styles/BrowseCars';

class MakeList extends Component {
	formatMake(make) {
		make = make.replace(' ', '-');
		return make.toLowerCase();
	}
	formatProvince(province) {
		return province.replace(' ', '-');
	}
	render() {
		let region;
		if (this.props.city) {
			region = `/province/${this.formatProvince(this.props.province)}/${this.formatProvince(this.props.city)}/`;
		} else if (this.props.province) {
			region = `/province/${this.formatProvince(this.props.province)}/`;
		} else {
			region = '/';
		}
		if (this.props.aggregations) {
			const list = this.props.aggregations['makes'].buckets
				.filter(item => (
					item.key !== ''
				)).map(item => (
					<li key={item.key}><Link to={`/cars${region}${this.formatProvince(item.key)}`}> {item.key}</Link>
					</li>
				));
			return (
				<div css={BrowseCars}>
					<ul>
						{list}
					</ul>
				</div>
			);
		}
		return null;
	}
}


export default MakeList;
