import React from 'react';
import Gumtree from '../../../static/images/Gumtree.png';
import Autotrader from '../../../static/images/autotrader.jpg';
import Cars from '../../../static/images/cars.png';
import OLX from '../../../static/images/OLX.png';

const SiteLogo = (props) => {
	switch (props.site) {
		case 'Gumtree':
			return <img width="100px" src={Gumtree} alt="Gumtree" />;
		case 'Cars':
			return <img width="100px" src={Cars} alt="Cars" />;
		case 'Autotrader':
			return <img width="100px" src={Autotrader} alt="Autotrader" />;
		case 'OLX':
			return <img width="100px" src={OLX} alt="OLX" />;
	
		default:
			return '';
	}
};

export default SiteLogo;
