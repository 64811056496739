import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Container, { appContainer, resultsContainer } from '../../styles/Container';
import history from '../history';
import BrowseModel from '../browse/BrowseModel';
import { SearchButton } from '../../styles/Button';
import SearchHeader from '../SearchHeader';
import Footer from '../Footer';
import Flex, { FlexChild } from '../../styles/Flex';

export default class BrowseModelPage extends Component {

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}

	serialiseParams(data) {
		return Object.keys(data).map(key => key + '=' + encodeURI("[" + data[key] + "]")).join('&');
	}

	handleClick = () => {
		history.push(`/results?Make=["${this.props.match.params.make}"]&Year=[1970,2018]&Price=[10000,2000000]&Mileage=[100,200000]`);
	}
	render() {
		const make = `${this.capitalizeFirstLetter(this.props.match.params.make).replace('-', ' ')}`;
		var region = this.props.match.params.province ? this.props.match.params.province : false;
		region =  region && region != "KwaZulu-Natal"  ? region.replace('-', ' ') : region
		const city = this.props.match.params.city ? (this.props.match.params.city).replace('-', ' ') : null;

		return (
			<Container>
				<Helmet>
					<meta name="description" content={`Find the best deals on ${make} cars in ${region || 'South Africa'} | Auto Forest`} />
					<title>{`${make}`} cars for sale in {(city ? `${city},` : '')} {(region || 'South Africa')} | Auto Forest</title>
					<link rel="canonical" href={window.location.href} />
				</Helmet>
				<SearchHeader visible={false} />
				<h2 style={{ textAlign: 'center' }}>
					{`${this.capitalizeFirstLetter(make)}`} Models in {(city ? `${city},` : '')} {(region || 'South Africa')}
				</h2>
				<input
					type="submit"
					css={SearchButton}
					value={`Find a ${this.capitalizeFirstLetter(this.props.match.params.make)}`}
					onClick={this.handleClick}
				/>

				<BrowseModel make={this.props.match.params.make == "MERCEDES-BENZ" ? this.props.match.params.make : this.props.match.params.make.replace('-', ' ')}
					province={region} city={city} />
				<Footer />
			</Container >
		);
	}
}
