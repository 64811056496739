import { css } from '@emotion/core';
import styled from '@emotion/styled';
import theme from './theme';
import { queries } from './mediaQueries';

const sourceLogosHome = css`
position: static;
left: 0;
right: 0;
bottom: 150px;
z-index: 200;
width:100%;
max-width:500px;
margin-left: auto;
margin-right: auto;
display: none;
${queries.medium`
display:initial;
`};
`;

export default sourceLogosHome;
