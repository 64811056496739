import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../styles/Navbar';
import Flex from '../styles/Flex';
import logo from '../../static/images/AF.png';
import history from '../components/history';
import Breadcrumbs from './partial/Breadcrumbs';

class SearchHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: '',
		};
	}

	doSearch = () => {
		history.push(`/results?Search="${this.state.searchText}"`);
	};

	updateSearchValue = (value) => {
		this.state.searchText = value;
	};

	render() {
		return (
			<Navbar>
				<Flex
					alignCenter
					responsive
					justifyContent="space-between"
				>
					<Link to="/"><img width="200px" src={logo} alt="logo" /></Link>
				</Flex>
				<Breadcrumbs />

			</Navbar>
		);
	}
}

export default SearchHeader;
