import React, { Component } from 'react';
import Container from '../../styles/Container';
import Navbar from '../../styles/Navbar';
import logo from '../../../static/images/AF.png';
import Footer from '../Footer';
import ContactForm from '../../styles/ContactForm';
import { Alert } from 'react-bootstrap';

export default class ContactPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			message: '',
			email: '',
			resultMsg: '',
			isButtonEnabled: true,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	handleSubmit(event) {
		this.setState({
			isButtonEnabled: false,
		});
		event.preventDefault();
		const form = event.target;
		const data = new URLSearchParams();
		data.append('name', this.state.name);
		data.append('message', this.state.message);
		data.append('email', this.state.email);
		data.append('formDataNameOrder', '["name","message","email"]');
		data.append('formGoogleSheetName', 'responses');
		fetch('https://script.google.com/macros/s/AKfycbzfhBvxnQ0LudbSqZ3-wHuMm-AeJhLnSdQuPu38/exec', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: data,
		}).then(res => res.json())
			.then((res) => {
				if (res.result === 'success') {
					this.setState({
						isButtonEnabled: true,
					});
					this.setState({
						resultMsg:
							<Alert variant='info'>
								Thank you we will be in touch soon!
							</Alert>
					}
					)
					form.reset()
				}
			});
	}
	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	}

	render() {
		return (
			<Container>
				<Navbar>
					<a href="/"><img width="200px" src={logo} alt="logo" /></a>
				</Navbar>
				<div css={ContactForm}>
					<form id="gform" className="contact" action="https://script.google.com/macros/s/AKfycbzfhBvxnQ0LudbSqZ3-wHuMm-AeJhLnSdQuPu38/exec" method="post" onSubmit={this.handleSubmit} >
						{this.state.resultMsg}
						<h2>Any questions, feedback, business opportunities? We'd love to hear from you!</h2>
						<fieldset>
							<input placeholder="Your name" name="name" type="text" tabIndex="0" required onChange={this.handleInputChange} />
						</fieldset>
						<fieldset>
							<input placeholder="Your Email Address" name="email" type="email" tabIndex="0" required onChange={this.handleInputChange} />
						</fieldset>
						<fieldset>
							<textarea name="message" placeholder="Type your message here...." tabIndex="0" required onChange={this.handleInputChange} />
						</fieldset>
						<fieldset>
							<button disabled={!this.state.isButtonEnabled} type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
						</fieldset>
					</form>
				</div>
				<Footer />
			</Container>
		);
	}
}
