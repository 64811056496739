import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { queries } from './mediaQueries';

const Container = styled.section`
	width: 100%;
	height: 100%;
    min-height: 100vh;
    position:relative;
    background-color: #fafafa;
	padding-bottom:80px;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Noto Sans","Ubuntu","Droid Sans","Helvetica Neue",sans-serif;

`;

export const modalDialog = css`
	@media (min-width: 576px) {
		width: 600px !important;
	}
`;

export const fullWidthResultsContainer = css`
	padding: 20px;
	.pagination {
		padding: 20px 0;
	}

	.card-image {
		background-size: cover;
	}

	.card-item {
		position: relative;
		${queries.small`
			height: 350px;
			width: 100%;
			margin: 5px 0;
			max-width: 100%;
		`}
	}

	${queries.large`
		width: 100%;
	`};
`;


export const resultsContainer = css`
	width: calc(100% - 360px);
	min-height:1064px;
	padding: 20px;
	.pagination {
		padding: 20px 0;
	}

	.card-image {
		background-size: cover;
	}

	.card-item {
		position: relative;
		${queries.small`
			height: 350px;
			width: 100%;
			margin: 5px 0;
			max-width: 100%;
		`}
	}

	${queries.large`
		width: 100%;
	`};
`;

export const homeMakeProvinceSection = css`
padding-bottom: 40px;
margin-top:10px;

a {
	color: #424242;

}
`;

export const homeSearchContainer = css`
padding: 20px;
margin-left: auto;
margin-right: auto;
position: absolute;
left: 0;
right: 0;
width: 0;
border-radius:10px;
min-width: 600px;
${queries.medium`
    min-width: 300px;
    background-color: initial;
`};
z-index: 1;
background-color: rgba(94, 196, 104, .7);
top: 200px;`;

export const homeBackgroundContainer = css`
	width: 100%;
	max-height: 900px;
    margin: 0;
  `;

export const homeMessageText = css`
	font-family: 'Source Sans Pro', sans-serif;
	color: white;
	font-weight: 300;
	text-align: center;
	position: relative;
	margin-bottom: 45px;
    margin-top: 30px;
    ${queries.medium`
     display: none
`};
`;

export const homeMessageTextMobile = css`
	font-family: 'Source Sans Pro', sans-serif;
	color: black;
	padding: 10px;
	font-weight: 300;
	text-align: center;
	position: relative;
	z-index:50;
	display: none;
	${queries.medium`
	display: table;
	`};


`;

export const homeBackgroundImage = css`
	max-height: 900px;
`;

export const categorySearchContainer = css`
	margin-left: 20px;
	width: calc(100% - 350px);
	${queries.medium`
		margin-left: 0;
		width: 100%;
	`};
`;


export const appContainer = css`
	margin: 0 1rem;

`;

export default Container;
