import React, { Component } from 'react';
import GoogleAnalytics from "react-ga4";

GoogleAnalytics.initialize('G-TCZ6C8S3B1');

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = (page) => {
		GoogleAnalytics.set({
			page,
			...options,
		});
		GoogleAnalytics.send("pageview");
	};

	const HOC = class extends Component {
		componentDidMount() {
			const page = this.props.location.pathname + this.props.location.search;
			trackPage(page);
		}

		componentWillReceiveProps(nextProps) {
			const currentPage = this.props.location.pathname + this.props.location.search;
			const nextPage = nextProps.location.pathname + this.props.location.search;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;
