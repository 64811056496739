import React from 'react';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';


const routes = [
];

// map & render your breadcrumb components however you want.
// each `breadcrumb` has the props `key`, `location`, and `match` included!
const Breadcrumbs = ({ breadcrumbs }) => (
	<div>
		{breadcrumbs.map((breadcrumb, index) => (
			<span style={{ color: 'white' }} key={breadcrumb.key}>
				<NavLink style={{ color: 'white' }} to={breadcrumb.props.match.url}>
					{breadcrumb}
				</NavLink>
				{(index < breadcrumbs.length - 1) && <i> / </i>}
			</span>
		))}
	</div>
);

export default withBreadcrumbs(routes, { excludePaths: ['/cars/province'] })(Breadcrumbs);
