import React, { Component } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import PriceStatsResult from './PriceStatsResult';

export default class PriceStats extends Component {

	render() {
		return (
			<ReactiveComponent
				componentId="PriceStats" // a unique id we will refer to later

				react={{
					and: ['Price', 'Year', 'Make', 'Province', 'Model', 'Keyword','Mileage'],
				}}
				defaultQuery={() => (
					{
						aggs: {
							stats: {
								extended_stats: {
									field: 'price',
								},
							},
						},
					}
				)}

				render={({ aggregations, setQuery }) => (
					<PriceStatsResult aggregations={aggregations} />
				)}
			>
			</ReactiveComponent>
		);
	}
}
