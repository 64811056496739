import React, { Component } from 'react';
import { BrowseCard } from '../styles/Card';
import ResultRow from './ResultRow';
import { BrowseCarModels } from '../styles/BrowseCars';

class ModelList extends Component {
	render() {
		if (this.props.aggregations) {
			const models = this.props.aggregations['models'].buckets.
				filter(item => (
					item.key !== ''
				)).map(item => (
					<BrowseCard
						key={item.key}
						href={`${window.location.pathname}/${item.key}`}>
						{item.key}
						<ResultRow row={item.tops.hits} />
					</BrowseCard>

				));
			return (
				<div css={BrowseCarModels}>
					{models}
				</div>
			);
		}

		return null;
	}
}


export default ModelList;
