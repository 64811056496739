import React from 'react';
import Card, { CardImage, Title } from '../../styles/Card';

export default class AdSearchComponent extends React.Component {
  componentDidMount () {
    var query = this.props.selectedMakes || 'Used Automotive Vehicles for Sale in South Africa';
    (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(
        arguments)},g[o]['t']=1*new Date})(window,'_googCsa');
        var pageOptions = {
            "pubId": "pub-3108024761418752", 
            "query": query,
            "styleId": "8553481244",
            "adPage": 1,
            "channel": this.props.container_id == 'ad1' ? "2987856658" : "7374725506",
            "location": false,
            "sellerRatings": false,
            "siteLinks": false
          };
        
          var adblock1 = {
            "container": this.props.container_id,
            "width": "250",
            "number": 1
          };
        

        
          _googCsa('ads', pageOptions, adblock1);
  }


render () {
    return (
        <Card style={{ maxWidth: '100%', flexBasis: '100%'}}  className='card-item' id={this.props.container_id} key={this.props.container_id}></Card>
    );
  }
}