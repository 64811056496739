import ReactiveList from '@appbaseio/reactivesearch/lib/components/result/ReactiveList';
import { ClassNames } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { container } from '../styles/Card';
import { modalDialog } from '../styles/Container';
import Spinner from '../styles/Spinner';
import Adsense from './partial/Adsense';
import AdSearchComponent from './partial/AdsenseSearch';
import LoadingOverlay from './partial/Loading';
import ResultCard from './ResultCard';
class Results extends Component {
	static propTypes = {
		selectedMakes: PropTypes.string,
		hidden: PropTypes.bool,
		onResultStats: PropTypes.func,
		onNoResults: PropTypes.func,
		query: PropTypes.object, // eslint-disable-line react/forbid-prop-types
	}

	constructor(props) {
		super(props);
		this.numResults = 10;
		this.firstRender = false;
		this.state = {
			pageNumber: 1,
			modalContent: '',
			showModal: false,
			noResults: false,
		};
	}


	hiddenStyle = () => {
		if (this.props.hidden) {
			return { display: 'none' };
		}
		return null;
	};

	onError = (values) => {
		if (this.props.onError) {
			this.props.onError(values);
		}
	}

	onPageChange = (pageNumber) => {
		if (pageNumber != this.state.pageNumber && window.ga) {
			var params = new URLSearchParams(window.location.search);
			params.delete('resultlist');
			params.append('resultlist', pageNumber)
			var newUrl = window.location.pathname + '?' + params.toString()
			window.ga('send', 'pageview', {
				page: newUrl
			});
			this.setState({ pageNumber: pageNumber })
		}
		window.scrollTo(0, 0);
	}

	onNoResults = () => {
		//stupid workaround cause this method seems to be called on first render regardless
		if (this.firstRender == false) {
			this.firstRender = true;
			return
		}
		if (this.state.noResults == false) {
			this.setState({ noResults: true })
		}
		if (this.props.suggestedWhenNothingFound) {
			return this.props.suggestedWhenNothingFound()
		}
		return (<div>No Results Found</div>)
	}

	saveResults = (values) => {
		if (this.props.onResultStats && values.numberOfResults) {
			if (this.numResults !== values.numberOfResults) {
				this.numResults = values.numberOfResults;
				this.props.onResultStats(values.numberOfResults);
			}
		}
	};

	renderItem = (res) => (
		<ResultCard onClickResult={this.handleClickResult} key={res._id} data={res} />
	);

	renderResults = (results) => {
		return (
			<ClassNames>
				{({ css }) => (
					<div className={css(container)}>
						<AdSearchComponent key={`ad1_${this.state.pageNumber}_${this.props.selectedMakes}`} container_id={"ad1"} selectedMakes={this.props.selectedMakes} />
						{results.data.map((item, index) => {
							return (
								<>
									{!this.props.hidden && index == 10 && <Adsense key={`ad3_${this.state.pageNumber}_${this.props.selectedMakes}`} slot="2346673805" />}
									{this.renderItem(item)}
								</>
							)
						})}
						<AdSearchComponent key={`ad2_${this.state.pageNumber}_${this.props.selectedMakes}`} container_id={"ad2"} selectedMakes={this.props.selectedMakes} />
					</div>
				)}
			</ClassNames>
		)
	}
	handleClickResult = (title, content) => {
		this.setState({
			showModal: true,
			modalContent: content,
			modalTitle: title,
		});
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false,
		});
	}

	render() {
		var _this = this;
		const baseQuery = { track_total_hits: true };
		const combinedQuery = this.props.query
		  ? () => ({
			  ...baseQuery,
			  ...this.props.query(),
			})
		  : () => baseQuery;
		return (
			<div style={{ position: 'relative' }}>
				<ReactiveList
					componentId="resultlist"
					react={{
						and: ['PriceStats', 'Price', 'Search', 'Make', 'Model',
							'Year', 'Province', 'Mileage', 'Keyword'],
					}}
					{...(!this.state.noResults && {
						sortOptions: [
							{
								label: 'Deal Rating - Cheapest',
								dataField: 'predicted_savings',
								sortBy: 'asc',
							},
							{
								label: 'Latest',
								dataField: 'updatedDate',
								sortBy: 'desc',
							},

							{
								label: 'Deal Rating - Most Expensive',
								dataField: 'predicted_savings',
								sortBy: 'desc',
							},
							{
								label: 'Price Lowest',
								dataField: 'price',
								sortBy: 'asc',
							},
							{
								label: 'Price Highest',
								dataField: 'price',
								sortBy: 'desc',
							},
							{
								label: 'Year Lowest',
								dataField: 'year',
								sortBy: 'asc',
							},
							{
								label: 'Year Highest',
								dataField: 'year',
								sortBy: 'desc',
							},
							{
								label: 'Mileage Lowest',
								dataField: 'mileage',
								sortBy: 'asc',
							},
							{
								label: 'Mileage Highest',
								dataField: 'mileage',
								sortBy: 'desc',
							},
						]
					})}
					style={this.hiddenStyle()}
					dataField="make"
					pagination
					pages={5}
					render={this.renderResults}
					URLParams
					defaultQuery={combinedQuery}
					loader={<LoadingOverlay />}
					size={20}
					renderResultStats={stats => this.saveResults(stats)}
					renderNoResults={this.onNoResults}
					onError={this.onError}
					onPageChange={this.onPageChange}
				/>
				<ClassNames>
					{({ css }) => (
						<Modal contentClassName={css(modalDialog)} show={this.state.showModal} onHide={this.handleCloseModal}>
							<Modal.Header closeButton>
								<Modal.Title>{this.state.modalTitle}</Modal.Title>
							</Modal.Header>
							<Modal.Body>{this.state.modalContent}</Modal.Body>
						</Modal>
					)}
				</ClassNames>
				<div className="loadingOverlay">
					<Spinner />
				</div>
			</div>
		);
	}
}


export default Results;
