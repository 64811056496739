import React, { Component } from 'react';
import Select from 'react-select';

export default class SelectDropdownWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectKey: this.props.forMake ? 'make' : 'model',
			version: 1,
		};
	}

	componentWillMount() {
		if (this.props.displayedValue && this.props.displayedValue.value) {
			const query = {
				query: {
					term: { [this.state.selectKey]: this.props.displayedValue.value },
				},
			};

			this.props.setQuery({
				query,
				value: this.props.displayedValue.value,
			});

			if (this.props.onChange) {
				this.props.onChange(this.props.displayedValue);
			}
		}
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.shouldClear != this.props.shouldClear) && this.props.shouldClear) {
			this.props.setQuery({
				value: '',
			});
		}
	}

	handleChange = (selectedOption) => {

		if (this.props.onChange) {
			this.props.onChange(selectedOption);
		}
		const queryArray = selectedOption.value.split(',').map((obj, item) => (
			{
				term: {
					[this.state.selectKey]: obj,
				},
			}
		));
		const query = {
			bool: {
				should: queryArray,
			},
		};
		this.props.setQuery({
			query,
			value: selectedOption.value,
		});
	}


	render() {
		let list;
		const customStyles = {
			container: () => ({
				// none of react-select's styles are passed to <Control />
				width: '100%',
			}),
			menu: () => ({
				// none of react-select's styles are passed to <Control />
				// width: '100%',
				backgroundColor: 'white',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'rgb(204, 204, 204)',
				//position:  'absolute',
				zIndex: "999"
			}),
			menuPortal: base => ({
				...base, zIndex: 9999, backgroundColor: 'white',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'rgb(204, 204, 204)',
			})

		}

		if (this.props.aggregations) {
			list = this.props.aggregations[this.state.selectKey].buckets
				.filter(item => (
					item.key !== ''
				)).map(item => (
					{
						value: item.key,
						label: item.key,
					}
				));
		}
		return (
			<Select
				name="form-field-name"
				options={list}
				value={this.props.displayedValue}
				menuPortalTarget={document.body}
				menuPosition={'fixed'}
				menuPlacement={'bottom'}
				placeholder={this.props.placeholder || 'Select...'}
				clearable={false}
				isDisabled={this.props.isEnabled == false || false}
				isMulti={false}
				onChange={this.handleChange}
				styles={customStyles}

			/>
		);
	}
}
