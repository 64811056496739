import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactiveBase from '@appbaseio/reactivesearch/lib/components/basic/ReactiveBase';
import Results from '../Results';
import Container, { appContainer, fullWidthResultsContainer } from '../../styles/Container';
import SearchHeader from '../SearchHeader';
import FilterContainer, { FilterContainerContents } from './../../styles/FilterContainer';
import SearchFilters from '../SearchFilters';
import Flex, { FlexChild } from '../../styles/Flex';
import { SearchButton } from '../../styles/Button';
import history from '../history';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

export default class BrowseModelResultsPage extends Component {
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}

	handleClick = () => {
		const make = (this.props.match.params.make) == "MERCEDES-BENZ" ? this.props.match.params.make : this.props.match.params.make.replace('-', ' ');
		const model = (this.props.match.params.make) == "MERCEDES-BENZ" ? this.props.match.params.model : this.props.match.params.model.replace('-', ' ');

		history.push(`/results?Make=["${make}"]&Model=["${model}"]&Year=[1970,2018]&Price=[10000,2000000]&Mileage=[100,200000]`);
	};

	getSuggested = () => {

		const make = (this.props.match.params.make) == "MERCEDES-BENZ" ? this.props.match.params.make : this.props.match.params.make.replace('-', ' ');
		const model = (this.props.match.params.make) == "MERCEDES-BENZ" ? this.props.match.params.model : this.props.match.params.model.replace('-', ' ');
		return(
		<div>
		<h4>No results at the moment</h4>
		<h5>Try the following:</h5>
		<Link to={`/results?Make=["${make}"]&Model=["${model}"]&Year=[1970,2018]&Price=[10000,2000000]&Mileage=[100,200000]`}>{this.capitalizeFirstLetter(make)} {this.capitalizeFirstLetter(model)} for sale</Link>
		<br/>
		{this.props.match.params.province && <Link to={`/results?Make=["${make}"]&Model=["${model}"]&Province=["${this.props.match.params.province}"]&Year=[1970,2018]&Price=[10000,2000000]&Mileage=[100,200000]`}>{this.capitalizeFirstLetter(make)} {this.capitalizeFirstLetter(model)} for sale in {this.capitalizeFirstLetter(this.props.match.params.province)}</Link>}
		</div>

		)
	};

	getQuery = () => {
		const formedQuery = {};

		if (this.props.match.params.make) {
			formedQuery.make = (this.props.match.params.make) == "MERCEDES-BENZ" ? this.props.match.params.make : this.props.match.params.make.replace('-', ' ');
		}

		if (this.props.match.params.model) {
			formedQuery.model = (this.props.match.params.make) == "MERCEDES-BENZ" ? this.props.match.params.model : this.props.match.params.model.replace('-', ' ');
		}

		if (this.props.match.params.province) {
			formedQuery.province = this.props.match.params.province != 'KwaZulu-Natal' ? this.props.match.params.province.replace('-', ' ') : this.props.match.params.province; 
		}

		if (this.props.match.params.year) {
			formedQuery.year = this.props.match.params.year;
		}

		if (this.props.match.params.city) {
			formedQuery.city = (this.props.match.params.city).replace('-', ' ');
		}
		const queryArray = Object.keys(formedQuery).map(item => (
			{
				match: {
					[item]: formedQuery[item],
				},
			}
		));
		return { query: queryArray };
	}

	render() {
		const makeModel = `${this.capitalizeFirstLetter(this.props.match.params.make)} ${this.capitalizeFirstLetter(this.props.match.params.model)}`;
		const province = this.props.match.params.province ? (this.props.match.params.province).replace('-', ' ') : 'South Africa';
		const city = this.props.match.params.city ? (this.props.match.params.city).replace('-', ' ') : '';
		const year = this.props.match.params.year ? this.props.match.params.year : '';

		return (
			<Container>
				<SearchHeader visible={false} />
				<Helmet>
					<meta name="description" content={`Auto Forest - Find the best ${year} ${makeModel} deals in ${province}, from one place!`} />
					<title>{`${year} ${makeModel} cars for sale in ${city} ${province} | Auto Forest`}</title>
					<link rel="canonical" href={window.location.href} />
				</Helmet>
				<ReactiveBase
					app={process.env.REACT_APP_ELASTIC_APPNAME}
					credentials="user:QGeZgT15nSau"
					url={process.env.REACT_APP_ELASTIC_URL}
				>
					<h2 style={{ textAlign: 'center' }}>
						{`${year} ${makeModel} in`} {(city ? `${city},` : '')} {province}
					</h2>
					<input
						type="submit"
						css={SearchButton}
						value={`Find a ${makeModel}`}
						onClick={this.handleClick}
					/>
					<Flex justifyContent="center" css={appContainer} direction="row-reverse">
						<FlexChild css={fullWidthResultsContainer}>
							<Results
								selectedMakes={this.props.match.params.make}
								query={() => (
									this.getQuery()
								)
								}
								suggestedWhenNothingFound={() => (this.getSuggested())}
							/>
						</FlexChild>
					</Flex>
				</ReactiveBase>
				<Footer />
			</Container>
		);
	}
}
