import React, { Component } from 'react';
import { ReactiveComponent, ReactiveBase } from '@appbaseio/reactivesearch';
import MakeList from '../MakeList';

export default class BrowseCity extends Component {
	buildQuery = () => {
		const size = 0;
		let queryPart = null;
		if (this.props.province) {
			queryPart = {
				query: {
					bool: {
						should: [
							{
								bool: {
									should: [
										{
											match: {
												province: this.props.province,
											},
										},
									],
								},
							},
						],
					},
				},

			};
		}
		const aggsPart = {
			makes: {
				terms: {
					field: 'city',
					size: 150,
					order: {
						_count: 'desc',
					},
				},
			},

		};

		return {
			size: 0,
			query: queryPart,
			aggs: aggsPart,
		};
	}

	render() {
		const province = this.props.province || null;
		return (
			<ReactiveBase
				app={process.env.REACT_APP_ELASTIC_APPNAME}
				credentials="user:QGeZgT15nSau"
				url={process.env.REACT_APP_ELASTIC_URL}
			>

				<ReactiveComponent
					componentId="carBrowser" // a unique id we will refer to later
					defaultQuery={() => (
						this.buildQuery()
					)}
					render={({ aggregations, setQuery }) => (
						<MakeList aggregations={aggregations} setQuery={setQuery} 
						province={province} />
					)}
				>
				</ReactiveComponent>
			</ReactiveBase>
		);
	}
}
