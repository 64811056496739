import React, { Component } from 'react';
import { Link } from "react-router-dom";
import BrowseCars from '../styles/BrowseCars';

class ProvinceList extends Component {
	formatMake(make) {
		make = make.replace(' ', '-').toLowerCase();
		return make.toLowerCase();
	}
	formatProvince(province) {
		return province.replace(' ', '-');
	}
	render() {
		if (this.props.aggregations) {
			const list = this.props.aggregations['provinces'].buckets
				.filter(item => (
					item.key !== ''
				)).map(item => (
					<li key={item.key}><Link to={`/cars/province/${this.formatProvince(item.key)}`}> {item.key}</Link>
					</li>
				));
			return (
				<div css={BrowseCars}>
					<ul>
						{list}
					</ul>
				</div>
			);
		}
		return null;
	}
}


export default ProvinceList;
