import React, { Component } from 'react';
import { ReactiveBase, ReactiveComponent } from '@appbaseio/reactivesearch';
import ModelList from '../ModelList';

export default class BrowseModel extends Component {

	getQueryPart = () => {
		const formedQuery = {};

		if (this.props.make) {
			formedQuery.make = this.props.make;
		}

		if (this.props.province) {
			formedQuery.province = this.props.province;
		}
		if (this.props.city) {
			formedQuery.city = this.props.city;
		}

		const queryArray = Object.keys(formedQuery).map(item => (
			{
				match: {
					[item]: formedQuery[item],
				},
			}
		));

		return queryArray;
	}

	getFullQuery = () => {
		const queryPart = this.getQueryPart();
		const aggsPart = {
			models: {
				terms: {
					field: 'model',
					size: 100,
					order: {
						_key: 'asc',
					},
				},
				aggs:
				{
					tops: {
						top_hits: {
							size: 1,
						},
					},
				},
			},
		};
		return {
			size: 0,
			query: queryPart,
			aggs: aggsPart,
		};
	}

	render() {
		return (
			<ReactiveBase
				app={process.env.REACT_APP_ELASTIC_APPNAME}
				credentials="user:QGeZgT15nSau"
				url={process.env.REACT_APP_ELASTIC_URL}
			>

				<ReactiveComponent
					componentId="carBrowser" // a unique id we will refer to later
					defaultQuery={() => (
						this.getFullQuery()
					)}
					render={({ aggregations, setQuery }) => (
						<ModelList aggregations={aggregations} setQuery={setQuery} />
					)}
				>
				</ReactiveComponent>

			</ReactiveBase>
		);
	}


}