import React from 'react';
import PropTypes from 'prop-types';

export default class Adsense extends React.Component {
  componentDidMount() {
    if(window) (window.adsbygoogle = window.adsbygoogle || []).push({});
  };
  render() {
    return (
      <ins className={`adsbygoogle`}
        style={{ display: 'flex', flexBasis: '100%'}}
        data-ad-client={this.props.client}
        data-ad-slot={this.props.slot}
        data-ad-format={this.props.format}
        data-full-width-responsive={this.props.responsive}></ins>
    );
  }
};

Adsense.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  layoutKey: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string
};

Adsense.defaultProps = {
  client: 'ca-pub-3108024761418752',
  format: 'auto',
  responsive: 'true'
};