import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { queries } from './mediaQueries';

const ContactForm = css`

	max-width: 400px;
	width: 100%;
	margin: 0 auto;
	position: relative;
  
	#resultMsg{
		font-size:14px;
	}
	
	.contact {
		font-family: "Roboto", Helvetica, Arial, sans-serif;
		font-weight: 100;
		font-size: 12px;
		line-height: 30px;
		color: #000;
	}

	* {

		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
	  }

	.contact fieldset
	{
		border: medium none !important;
		margin: 0 0 10px;
		min-width: 100%;
		padding: 0;
		width: 100%;
	  }
	  

  
	.contact {
		background: #F9F9F9;
		padding: 25px;
		margin: 150px 0;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
	  }

	  .contact input[type="text"],
	  .contact input[type="email"],
	  .contact input[type="tel"],
	  .contact input[type="url"],
	  .contact textarea {
		width: 100%;
		border: 1px solid #ccc;
		background: #FFF;
		margin: 0 0 5px;
		padding: 10px;
	  }

	  	.contact input[type="text"],
		.contact input[type="email"],
		.contact input[type="tel"],
		.contact input[type="url"],
		.contact textarea,
		.contact button[type="submit"] {
		font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
	}

	.contact textarea {
		height: 100px;
		max-width: 100%;
		resize: none;
	  }
	  
	  .contact button[type="submit"]:hover {
		background: #43A047;
		-webkit-transition: background 0.3s ease-in-out;
		-moz-transition: background 0.3s ease-in-out;
		transition: background-color 0.3s ease-in-out;
	  }
	  
	  .contact button[type="submit"]:disabled {
		background: #CCCCCC;
	  }

	  .contact input:focus,
	.contact textarea:focus {
	outline: 0;
	border: 1px solid #aaa;
}

.contact button[type="submit"] 
{
	cursor: pointer;
	width: 100%;
	border: none;
	background: #4CAF50;
	color: #FFF;
	margin: 0 0 5px;
	padding: 10px;
	font-size: 15px;
  }

`;

export default ContactForm;
