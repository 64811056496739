import React, { Component } from 'react';

export default class PriceStatsResult extends Component {

	render() {
		if (this.props.aggregations && this.props.aggregations.stats.avg) {
			return (
				<div>
				 💵Average Price: {"R" + parseFloat(this.props.aggregations.stats.avg.toFixed(2)).toLocaleString() }
				</div>
			);
		}
		return (
			<div></div>
		);
	}
}
