import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import ResultsPage from './components/pages/ResultsPage';
import HomePage from './components/pages/HomePage';
import appHistory from './components/history';
import withTracker from './components/partial/Tracker';
import ContactPage from './components/pages/ContactPage';
import Page404 from './components/pages/Page404';
import BrowseModelPage from './components/pages/BrowseModelPage';
import BrowseMakePage from './components/pages/BrowseMakePage';
import BrowseCityPage from './components/pages/BrowseCityPage';
import BrowseModelResultsPage from './components/pages/BrowseModelResultsPage';
import './App.css';

const App = () => (
	<Router history={appHistory}>
		<Switch>
			<Route exact path="/" component={withTracker(HomePage)} />
			<Route exact path="/results" component={withTracker(ResultsPage)} />
			<Route exact path="/contact" component={withTracker(ContactPage)} />
			<Route exact path="/cars/" component={withTracker(BrowseMakePage)} />
			<Route exact path="/cars/:make/" component={withTracker(BrowseModelPage)} />
			<Route exact path="/cars/province/:province/:city/:make/" component={withTracker(BrowseModelPage)} />
			<Route exact path="/cars/province/:province/" component={withTracker(BrowseCityPage)} />
			<Route exact path="/cars/province/:province/:city" component={withTracker(BrowseMakePage)} />
			<Route exact path="/cars/:make/:model" component={withTracker(BrowseModelResultsPage)} />
			<Route exact path="/cars/:make/:model/:year" component={withTracker(BrowseModelResultsPage)} />
			<Route exact path="/cars/province/:province/:city/:make/:model" component={withTracker(BrowseModelResultsPage)} />

			<Route component={Page404} />
		</Switch>
	</Router>
);

export default App;
