import React, { Component } from 'react';
import ReactiveBase from '@appbaseio/reactivesearch/lib/components/basic/ReactiveBase';
import SelectedFilters from '@appbaseio/reactivesearch/lib/components/basic/SelectedFilters';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faFilter from '@fortawesome/fontawesome-free-solid/faFilter';
import { Helmet } from 'react-helmet';
import SearchHeader from '../SearchHeader';
import ModalHeader from '../partial/ModalHeader';
import SearchFilters from '../SearchFilters';
import Results from '../Results';
import Container, { appContainer, resultsContainer } from './../../styles/Container';
import Flex, { FlexChild } from './../../styles/Flex';
import FilterContainer, { FilterContainerContents } from './../../styles/FilterContainer';
import { ToggleFilters } from '../../styles/Button';
import Footer from '../Footer';
import PriceStats from '../pricestats/PriceStats';

class ResultsPage extends Component {
	constructor(props) {
		super(props);
		this.targetElement = null;
		this.state = {
			filterVisible: false,
			clearModel: false,
			resultsHidden: false,
			isUpdating: false,
			selectedMakes: false,
			numResults: '',
		};
	}
	componentDidUpdate(prevProps) {
		if (this.state.clearModel) {
			this.setState({ clearModel: false })
		}
	}

	onClearFilters = (component, value) => {
		if (component == 'Make') {
			this.setState({
				clearModel: true,
			});
		}
	}

	filterValuesChanged = (values) => {
		if(values.Make && values.Make.value != this.state.selectedMakes) {
			this.setState({
				selectedMakes: values.Make.value
			})
		}
	}

	toggleFilters = () => {
		const { filterVisible } = this.state.filterVisible;
		const { resultsHidden } = this.state.resultsHidden;
		this.setState({
			filterVisible: !filterVisible,
			resultsHidden: !resultsHidden,
		});
	};

	getResultStats = (values) => {
		this.setState({
			isUpdating: true,
			numResults: values,
		});

		setTimeout(() => { this.setState({ isUpdating: false }); }, 350);
	}

	closeFilter = () => {
		this.setState({
			filterVisible: false,
			resultsHidden: false,
		});
	};

	render() {
		return (
			<Container>
				<Helmet>
					<meta name='robots' content='noindex, nofollow' />
				</Helmet>
				<ReactiveBase
					app={process.env.REACT_APP_ELASTIC_APPNAME}
					credentials="user:QGeZgT15nSau"
					url={process.env.REACT_APP_ELASTIC_URL}
				>
					<SearchHeader visible={false} />
					<Flex css={appContainer} direction="row-reverse">
						<FilterContainer className="modalFilter" visible={this.state.filterVisible}>
							<ModalHeader isUpdating={this.state.isUpdating} onClose={this.closeFilter} title={`Results (${this.state.numResults})`} />
							<SearchFilters clearModel={this.state.clearModel} />
						</FilterContainer>
						<FlexChild css={resultsContainer}>
							<div>{this.state.numResults} Results found</div>
							<SelectedFilters
								onChange={this.filterValuesChanged}
								showClearAll
								clearAllLabel="Clear filters"
								onClear={this.onClearFilters}
							/>
							<PriceStats />
							<Results onResultStats={this.getResultStats}  selectedMakes={this.state.selectedMakes}  hidden={this.state.resultsHidden} />
							<br /><br />
						</FlexChild>
					</Flex>
					<ToggleFilters onClick={this.toggleFilters}>
						Filter <FontAwesomeIcon icon={faFilter} />
					</ToggleFilters>
				</ReactiveBase>
				<Footer />
			</Container>
		);
	}
}

export default ResultsPage;
