import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { queries } from './mediaQueries';

const BrowseCars = css`

width: 75%;
${queries.medium`
width: 100%;
`};
margin: auto;

ul {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 0.9rem;
	list-style: none;
	}

li {
		width: calc(100% / 3);
		${queries.medium`
			width: calc(100% / 2);
			padding:8px;

		`};
		padding: 4px;
	}

a {
	color: #424242;

}
`;

export const BrowseCarModels = css`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
`;

export default BrowseCars;
