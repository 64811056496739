import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { queries } from './mediaQueries';

const FilterContainer = styled.div`
	width: 330px;
	left: 0;
	padding-left: 10px;
	max-height: 100%;
	overflow-y: initial;
	position: absolute;
	background: #fafafa;
	z-index: 2;
	.list {
		max-height: 140px;
	}
	${queries.large`
		width: 100%;
		min-height: 100vh;
		padding-right: 10px;
	`};
	${queries.mlarge`
		height:100%;
		top:0;
		bottom:0;
		right:0;
		left:0;
		z-index:100;
		overflow-y: scroll;
		overflow-x: hidden;
		position: fixed;
	`};
	${queries.small`
		height:100%;
		top:0;
		bottom:0;
		right:0;
		left:0;
		z-index:100;
		overflow-y: scroll;
		overflow-x: hidden;
		position: fixed;
	`};
	${({ visible }) => !visible && css`
		${queries.large`
			display: none;
		`};
	`};
`;

export default FilterContainer;
