import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Container from '../../styles/Container';
import BrowseCity from '../browse/BrowseCity';
import SearchHeader from '../SearchHeader';
import Footer from '../Footer';

export default class BrowseCityPage extends Component {
	render() {
		var region = this.props.match.params.province ? this.props.match.params.province : false;
		region =  region && region != "KwaZulu-Natal"  ? region.replace('-', ' ') : region

		return (
			<Container>
				<Helmet>
					<meta name="description" content={`Find the best deals on cars in ${region || 'South Africa'} | Auto Forest`} />
					<title>{`Cars for sale in ${region || 'South Africa'} | Auto Forest`}</title>
					<link rel="canonical" href={window.location.href} />
				</Helmet>
				<SearchHeader visible={false} />
				<h2 style={{ textAlign: 'center' }}>Cities in {region || 'South Africa'}</h2>
				<BrowseCity province={region} />
				<Footer />
			</Container>
		);
	}
}
