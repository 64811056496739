import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Container from '../../styles/Container';
import BrowseMake from '../browse/BrowseMake';
import SearchHeader from '../SearchHeader';
import Footer from '../Footer';

export default class BrowseMakePage extends Component {
	render() {
		var region = this.props.match.params.province ? this.props.match.params.province : false;
		region =  region && region != "KwaZulu-Natal"  ? region.replace('-', ' ') : region
		const city = this.props.match.params.city ? (this.props.match.params.city).replace('-', ' ') : null;
		return (
			<Container>
				<Helmet>
					<meta name="description" content={`Find the best deals on cars in ${region || 'South Africa'} | Auto Forest`} />
					<link rel="canonical" href={window.location.href} />
					<title>Cars for sale in {city !== null ? (city + ', ') : ''} {region || 'South Africa'} | Auto Forest</title>
				</Helmet>
				<SearchHeader visible={false} />
				<h2 style={{ textAlign: 'center' }}>Car makes in {city !== null ? (city + ', ') : ''} {region || 'South Africa'}</h2>
				<BrowseMake province={region} city={city} />
				<Footer />
			</Container>
		);
	}
}
