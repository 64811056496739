import React, { Component } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import SelectDropdownWrapper from './SelectDropdownWrapper';

export default class SelectModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shouldClear: false,
		};
	}

	onValueChange = (values) => {
		if (this.props.onValueChange) {
			this.props.onValueChange(values.value);
		}
	}

	buildQuery = () => {
		const size = 0;
		const queryPart = null;
		const aggsPart = {
			model: {
				terms: {
					field: 'model',
					size: 150,
					order: {
						_key: 'asc',
					},
				},
			},

		};

		return {
			size: 0,
			query: queryPart,
			aggs: aggsPart,
		};
	}

	displayValue = (value) => {
		if (value) {
			value = Array.isArray(value) ? value[0] : value
			return { 'label': value, 'value': value }
		}
		return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.make != '' && prevProps.make != this.props.make) {
			this.setState({ shouldClear: true })
		} else if (this.state.shouldClear) {
			this.setState({ shouldClear: false })
		}
	}

	render() {
		return (

			<ReactiveComponent
				componentId="Model" // a unique id we will refer to later
				react={{
					and: ['Price', 'Year', 'Make', 'Province'],
				}}
				defaultQuery={() => (
					this.buildQuery()
				)}
				URLParams
				render={({ aggregations, setQuery, value }) => {
					return (
						<SelectDropdownWrapper
							displayedValue={this.displayValue(value)}
							setQuery={setQuery}
							placeholder="All Models"
							aggregations={aggregations}
							isEnabled={this.props.isEnabled}
							shouldClear={this.state.shouldClear}
							isMulti={this.props.isMulti}
							forModel
							onChange={this.onValueChange} />
					)
				}}
			/>
		);
	}
}