import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactiveBase from '@appbaseio/reactivesearch/lib/components/basic/ReactiveBase';
import Container, { appContainer, homeSearchContainer, homeBackgroundContainer, homeMessageTextMobile, homeMessageText, homeBackgroundImage, homeMakeProvinceSection } from './../../styles/Container';
import BrowseMakeProvince from '../browse/BrowseMakeProvince';
import Flex from './../../styles/Flex';
import Results from '../Results';
import SearchBox from '../SearchBox';
import Navbar from '../../styles/Navbar';
import logo from '../../../static/images/AF.png';
import sources from '../../../static/images/sources.png';
import Footer from '../Footer';
import sourceLogosHome from '../../styles/Images';
export default class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isError: false,
		};
	}

	handleError = (error) => {
		this.setState({
			isError: true,
		});
	}

	render() {
		return (
			<Container>
				<Helmet>
					<meta name="description" content="Auto Forest helps you find the best deals on new and used cars in South Africa, all from one place." />
					<title>{`Auto Forest - Find the best deals on Used Cars in South Africa`}</title>

				</Helmet>
				<ReactiveBase
					app={process.env.REACT_APP_ELASTIC_APPNAME}
					credentials="user:QGeZgT15nSau"
					url={process.env.REACT_APP_ELASTIC_URL}
				>
					<Navbar>
						<a href="/"><img width="200px" src={logo} alt="logo" /></a>
					</Navbar>
					<div css={homeBackgroundContainer}>
						<h3 css={homeMessageTextMobile}>
							Find the best deals on Used Cars in South Africa
						</h3>
						<img
							css={homeBackgroundImage}
							width="100%"
							alt="Logo"
							src="https://images.pexels.com/photos/56832/road-asphalt-space-sky-56832.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
						/>
					</div>
					<div css={homeSearchContainer}>
						<h2 css={homeMessageText}>Find the best deals on Used Cars in South Africa</h2>
						<SearchBox />
						{this.state.isError && <div style={{ color: 'white' }}>Sorry something has gone wrong. Please try again later.</div>}
					</div>

					<Flex css={appContainer} direction="row-reverse">
						<Results hidden onError={this.handleError} />
					</Flex>
				</ReactiveBase>
				<div css={homeMakeProvinceSection}>
					<BrowseMakeProvince />
				</div>
				<img css={sourceLogosHome} src={sources} alt="sources" />

				<Footer />
			</Container>
		);
	}
}
