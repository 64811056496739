import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import Rating from 'react-rating';

const popover = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Price Prediction</Popover.Title>
        <Popover.Content>
            To calculate our deal ratings, we compare the asking price of a vehicle to what we estimate to be its fair market value.
            The Autoforest Price Prediction of a vehicle is our estimated fair price for a vehicle based on analysis of comparable current and previous sold car listings in the market.
      </Popover.Content>
    </Popover>
);


const Deal = ({ variant, text, colour, rating = 5, diffValue, diffPercent }) => {
    let style = {}
    style['color'] = colour

    return (
        <div style={style}>
            <Badge style={{ backgroundColor: colour }} variant={variant}>{text}</Badge>
            <br />
            <Rating readonly
                emptySymbol={[<FontAwesomeIcon icon={farCircle} />]}
                fullSymbol={[<FontAwesomeIcon icon={faCircle} />]}
                initialRating={rating} />
            <br />
            {/* {Math.abs(Math.round(diffPercent))}% above our prediction */}
            {(diffPercent < 0) && `Predicted Savings: R${parseFloat(Math.abs(Math.round(diffValue))).toLocaleString()}`}
            <OverlayTrigger
                placement="top"
                overlay={popover}>
                <FontAwesomeIcon style={{ color: 'rgb(200 200 200)' }}
                    icon={faQuestionCircle} />
            </OverlayTrigger>
        </div>
    )
}
/*

Great deal 18+ - selling price more than 10 percent above predicted price
Good 6+
Average -6+
Bit Expensive 18+
Expensive -30+
*/
const getDealType = (predicted, actual) => {
    var diff = (actual - predicted) / predicted * 100
    var diffValue = actual - predicted
    if (diff < -18) {
        return <Deal text="Great Deal" variant="success"
            colour="#2F8037" rating={5}
            diffValue={diffValue} diffPercent={diff} />
    } else if (diff < -6) {
        return <Deal text="Good Deal" variant="primary"
            colour="#5FB467" rating={4}
            diffValue={diffValue} diffPercent={diff} />
    } else if (diff < 6) {
        return <Deal text="Average Deal" variant="info"
            colour="#6AC873" rating={3}
            diffValue={diffValue} diffPercent={diff} />
    } else if (diff < 18) {
        return <Deal text="Bit Expensive" variant="warning"
            colour="#ffe680" rating={2}
            diffValue={diffValue} diffPercent={diff} />
    } else if (diff <= 30) {
        return <Deal text="Expensive" variant="danger"
            colour="#de8787" rating={1}
            diffValue={diffValue} diffPercent={diff} />
    }
    return ""
}
const PricePredictor = ({ actual, predicted }) => {

    return (
        <div>
            {getDealType(predicted, actual)}
        </div>
    )
}

export default PricePredictor