import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { queries } from './mediaQueries';

const homeSearchBox = css`
    display:inherit;
    width: 70%;
    ${queries.medium`
        display:initial;
    `};
`;

export default homeSearchBox;
