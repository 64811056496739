import React, { Component } from 'react';
import { ReactiveComponent, ReactiveBase } from '@appbaseio/reactivesearch';
import MakeList from '../MakeList';

export default class BrowseMake extends Component {

	getQueryPart = () => {
		const formedQuery = {};

		if (this.props.province) {
			formedQuery.province = this.props.province;
		}
		if (this.props.city) {
			formedQuery.city = this.props.city;
		}

		const queryArray = Object.keys(formedQuery).map(item => (
			{
				match: {
					[item]: formedQuery[item],
				},
			}
		));

		return queryArray;
	}

	buildQuery = () => {
		const size = 0;
		const queryPart = this.getQueryPart();



		const aggsPart = {
			makes: {
				terms: {
					field: 'make',
					size: 150,
					order: {
						_key: 'asc',
					},
				},
			},

		};

		return {
			size: 0,
			query: queryPart,
			aggs: aggsPart,
		};
	}

	render() {
		const province = this.props.province || null;
		const city = this.props.city || null;

		return (
			<ReactiveBase
				app={process.env.REACT_APP_ELASTIC_APPNAME}
				credentials="user:QGeZgT15nSau"
				url={process.env.REACT_APP_ELASTIC_URL}
			>

				<ReactiveComponent
					componentId="carBrowser" // a unique id we will refer to later
					defaultQuery={() => (
						this.buildQuery()
					)}
					render={({ aggregations, setQuery }) => (
						<MakeList aggregations={aggregations} setQuery={setQuery} 
						province={province} city={city} />
					)}
				>
				</ReactiveComponent>
			</ReactiveBase>
		);
	}
}
