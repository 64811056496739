import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MultiDropdownList, RangeSlider, DataSearch } from '@appbaseio/reactivesearch';
import Flex, { FlexChild } from '../styles/Flex';
import SelectMake from './react-select/SelectMake';
import SelectModel from './react-select/SelectModel';

class SearchFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fromPrice: '',
			toPrice: '',
			fromYear: '',
			makeValue: '',
			toYear: '',
			fromMileage: '',
			toMileage: '',
		};
	}

	updateMakeValue = (value) => {
		this.setState({
			makeValue: value,
		});
	};

	updateModelValue = (value) => {
		this.setState({
			modelValue: value,
		});
	};

	componentDidUpdate(prevProps) {
		if(prevProps.clearModel != this.props.clearModel && this.props.clearModel) {
			this.setState({
				makeValue: '',
			});
		}
	}

	getPriceSliderValue = (values) => {
		this.setState({
			fromPrice: values[0],
			toPrice: values[1],
		});
	};

	getYearSliderValue = (values) => {
		this.setState({
			fromYear: values[0],
			toYear: values[1],
		});
	};

	getMileageSliderValue = (values) => {
		this.setState({
			fromMileage: values[0],
			toMileage: values[1],
		});
	};
	render() {
		return (
			<Flex className="filterContainer" modal direction="column">
				<FlexChild card margin="10px">
					<SelectMake onValueChange={this.updateMakeValue} />
					<SelectModel make={this.state.makeValue} onValueChange={this.updateModelValue} />
				</FlexChild>
				<FlexChild card margin="10px">
					<DataSearch
						componentId="Keyword"
						dataField={['title']}
						title="Keywords"
						autosuggest={false}
						showClear
						showIcon={false}
						URLParams
						placeholder="e.g. GTI"
						react={{
							and: ['Price', 'Year', 'Make'],
						}}
					/>
				</FlexChild>
				<FlexChild card margin="10px">
					<MultiDropdownList
						componentId="Province"
						dataField="province"
						URLParams
						title="Province"
						sortBy="count"
						placeholder="All Provinces"
						react={{
							and: ['Price', 'Year', 'Make', 'Model'],
						}}
						innerClass={{
							list: 'list',
						}}
					/>
				</FlexChild>

				<FlexChild card margin="10px">
					<RangeSlider
						componentId="Price"
						dataField="price"
						react={{
							and: ['Search', 'Make', 'Model', 'Year'],
						}}
						title="Price Range"
						URLParams
						tooltipTrigger="hover"
						renderTooltipData={data => (
							`R${parseFloat(data).toLocaleString()}`
						)}
						stepValue={50000}
						interval={50000}
						range={{
							start: 10000,
							end: 2000000,
						}}
						rangeLabels={{
							start: `R${parseFloat(this.state.fromPrice === '' ? 10000 : this.state.fromPrice).toLocaleString()}`,
							end: `R${parseFloat(this.state.toPrice === '' ? 2000000 : this.state.toPrice).toLocaleString()}`,
						}}
					/>
				</FlexChild>

				<FlexChild card margin="10px">
					<RangeSlider
						componentId="Year"
						dataField="year"
						react={{
							and: ['Search', 'Make', 'Model'],
						}}
						URLParams
						title="Year"
						range={{
							start: 2000,
							end: new Date().getFullYear(),
						}}
						defaultSelected={{
							start: 2000,
							end: new Date().getFullYear(),
						}}
						tooltipTrigger="hover"
						rangeLabels={{
							start: this.state.fromYear === '' ? '2000' : this.state.fromYear.toString(),
							end: this.state.toYear === '' ? new Date().getFullYear().toString() : this.state.toYear.toString(),
						}}
					/>
				</FlexChild>

				<FlexChild card margin="10px">
					<RangeSlider
						componentId="Mileage"
						dataField="mileage"
						react={{
							and: ['Search', 'Make', 'Model', 'Price', 'Year'],
						}}
						URLParams
						showHistogram
						snap
						tooltipTrigger="hover"
						renderTooltipData={data => (
							`${parseFloat(data).toLocaleString()} KM`
						)}
						title="Mileage"
						stepValue={5000}
						interval={5000}
						range={{
							start: 100,
							end: 200000,
						}}
						rangeLabels={{
							start: `${parseFloat(this.state.fromMileage === '' ? 100 : this.state.fromMileage).toLocaleString()} KM`,
							end: `${parseFloat(this.state.toMileage === '' ? 200000 : this.state.toMileage).toLocaleString()} KM`,
						}}
					/>
				</FlexChild>


			</Flex>
		);
	}
}

SearchFilters.propTypes = {
	currentTopics: PropTypes.arrayOf(PropTypes.string),
	setTopics: PropTypes.func,
	visible: PropTypes.bool,
};

export default SearchFilters;
