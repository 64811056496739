import React, { Component } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import SelectDropdownWrapper from './SelectDropdownWrapper';

export default class SelectMake extends Component {

	onValueChange = (values) => {
		if (this.props.onValueChange) {
			this.props.onValueChange(values.value);
		}
	}

	buildQuery = () => {
		const size = 0;
		const queryPart = null;
		const aggsPart = {
			make: {
				terms: {
					field: 'make',
					size: 150,
					order: {
						_key: 'asc',
					},
				},
			},

		};

		return {
			size: 0,
			query: queryPart,
			aggs: aggsPart,
		};
	}

	displayValue = (value) => {
		if (value) {
			value = Array.isArray(value) ? value[0] : value
			return { 'label': value, 'value': value }
		}
		return null
	}

	render() {
		return (

			<ReactiveComponent
				componentId="Make" // a unique id we will refer to later
				defaultQuery={() => (
					this.buildQuery()
				)}
				URLParams
				render={({ aggregations, setQuery, value }) => {
					return (
						<SelectDropdownWrapper
							displayedValue={this.displayValue(value)}
							setQuery={setQuery}
							placeholder="All Makes"
							aggregations={aggregations}
							isMulti={this.props.isMulti} 
							forMake 
							onChange={this.onValueChange} />
					)
				}}
			/>
		);
	}
}