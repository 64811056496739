import React, { Component } from 'react';
import { CloseButton } from '../../styles/Button';
import { ModalHeaderBar, ModalHeaderText } from '../../styles/ModalHeaderBar';

class ModalHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: true,
		};
	}

	hideModal = () => {
		this.props.onClose(false);
	};

	render() {
		return (
			<ModalHeaderBar>
				<CloseButton onClick={this.hideModal}>
					Done
				</CloseButton>
				<ModalHeaderText isUpdating={this.props.isUpdating}>{this.props.title}</ModalHeaderText>
			</ModalHeaderBar>
		);
	}
}

export default ModalHeader;
