import React, { Component } from 'react';
import Loading from '../../styles/Loading';


export default class LoadingOverlay extends Component {
	componentDidMount() {
		document.body.classList.add('loading-content');
	}

	componentWillUnmount() {
		document.body.classList.remove('loading-content');
	}

	render() {
		return (
			null
		);
	}
}
