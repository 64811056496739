import { css } from '@emotion/core';
import styled from '@emotion/styled';
const theme = {
	typography: {
		fontFamily: 'Open Sans, Helvetica, sans-serif',
	},
	colors: {
		primaryColor: '#3ba145',
		secondaryColor: '#3ba145',
		titleColor: 'rebeccapurple',
		accentColor: 'palevioletred',
		lightGray: '#eff3f6',
		white: '#ffff',
	},
};

export default theme;
