import { css } from '@emotion/core';
import styled from '@emotion/styled';

import theme from './theme';
import { queries } from './mediaQueries';

export const ModalHeaderBar = styled.div`
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    display: none;
    vertical-align: top !important;
    width: 100% !important;
    border-bottom: 1px solid #DBDBDB !important;
    position: fixed;
    z-index: 5;
    background: #fafafa;
    ${queries.large`
        display: table !important;
    `};

`;

export const ModalHeaderText = styled.div`
    display: table-cell !important;
    text-align: center !important;
    vertical-align: middle !important;
	width: 100%;
	transition: all .2s ease-in-out; 
	${props => (props.isUpdating ? 'transform: scale(1.2);font-weight:700;' : null)};
`;
