import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import {
	Button, Container, Row, Col,
} from 'react-bootstrap';
import SiteLogo from '../components/partial/SiteLogo';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faExternalLinkAlt from '@fortawesome/fontawesome-free-solid/faExternalLinkAlt'
import Card, { CardImage, Title } from '../styles/Card';
import { price } from '../styles/Topic';
import PricePredictor from './partial/PricePredictor';

export default class ResultCard extends Component {

	onClick = (url) => {
		if(window.ga) {
			window.ga('send', 'event', 'outbound', 'click', url);
		}
	}
	openModal = () => {
		if (this.props.onClickResult) {
			const res = this.props.data;
			let sites = Object.keys(res.sites).map(key => (
				<Row
					style={{
						paddingTop: "15px",
						paddingBottom: "15px"
					}}
					key={key}
				>
					<Col xs={6} lg={3}><SiteLogo site={res.sites[key].source} /></Col>
					<Col xs={6} lg={3}><a rel="external nofollow noopener noreferrer" 
					onClick={(e) => this.onClick(res.sites[key].link, e)}
					href={res.sites[key].link} target="_blank">{res.title}</a></Col>
					<Col xs={6} lg={3}><span>R{parseFloat(res.sites[key].price).toLocaleString()} </span></Col>
					<Col xs={6} lg={3}><Button rel="external nofollow noopener noreferrer" target="_blank"
					onClick={(e) => this.onClick(res.sites[key].link, e)}
					href={res.sites[key].link}>View Offer <FontAwesomeIcon icon={faExternalLinkAlt}/></Button></Col>
				</Row>
			));
			const modalContent = <Container>{sites}</Container>;
			this.props.onClickResult(res.title, modalContent);
		}
	}


	render() {
		const res = this.props.data;
		const numSites = Object.keys(res.sites).length;
		return (
			<Card
				key={res._id}
				className="card-item"
				href={res.link}
				target="_blank"
				rel="external nofollow noopener noreferrer"
			>
				<LazyLoad height={220}>
					<CardImage className="card-image" style={{ backgroundImage: `url(${res.image})` }} />
				</LazyLoad>
				<Title>{res.title} </Title>
				<article>
					<div css={price}>R{parseFloat(res.price).toLocaleString()}</div>
					<div>{res.mileage} KM</div>
					<div className="city">{res.city}</div>
					<div className="province">{res.province}</div>
					<div className="source">{res.source}</div>
					{res.predicted_price && <PricePredictor actual={res.price} predicted={res.predicted_price} />}
					{numSites > 1 && <Button onClick={this.openModal}> View {numSites} Offers</Button>}
					{numSites === 1 && <Button rel="external nofollow noopener noreferrer" target="_blank" 
					onClick={(e) => this.onClick(res.sites[Object.keys(res.sites)[0]].link, e)} 
					href={res.sites[Object.keys(res.sites)[0]].link}>View Offer<FontAwesomeIcon icon={faExternalLinkAlt}/></Button>}

				</article>
			</Card>
			
		);
	}
}
