import React from 'react';


const ResultRow = (props) => {
	return props.row.hits.map(item => (
		<img key={item._id} width="200px" alt="" src={item._source.image} />
	));
};

export default ResultRow;
