import { jsx } from '@emotion/core';
import React from 'react';
import FooterBar, { FooterContents } from '../styles/FooterBar';

const Footer = () =>
(
	<FooterBar>
		<div>
			<ul css={FooterContents}>
				<li>
					<a style={{ color: 'white' }} href="/contact">Contact Us</a>
				</li>
				<li>
					<b>&copy; {new Date().getFullYear()} Value Forest (Pty) Ltd.</b>
				</li>
			</ul>

		</div>
	</FooterBar>
);

export default Footer;

