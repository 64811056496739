import React from 'react';
import Container, { homeBackgroundContainer, homeBackgroundImage, homeMessageText, homeSearchContainer, homeMessageTextMobile } from '../../styles/Container';
import Navbar from '../../styles/Navbar';
import logo from '../../../static/images/AF.png';

const Page404 = () => {
	return (
		<Container>
			<Navbar>
				<a href="/"><img width="200px" src={logo} alt="logo" /></a>
			</Navbar>
			<div css={homeBackgroundContainer}>
				<h2 css={homeMessageTextMobile}>Looks like you&apos;ve wandered in the forest... <a href="/">Go back to the clearing</a></h2>
				<img
					css={homeBackgroundImage}
					width="100%"
					alt="Logo"
					src="https://images.pexels.com/photos/64191/pexels-photo-64191.jpeg?auto=compress&cs=tinysrgb&dpr=1&h=750&w=1260"
				/>
			</div>
			<div css={homeSearchContainer}>
				<h2 css={homeMessageText}>Looks like you&apos;ve wandered in the forest... <a href="/">Go back to the clearing</a></h2>

			</div>
		</Container>
	);
};

export default Page404;
