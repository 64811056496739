import React, { Component } from 'react';
import { ReactiveComponent, ReactiveBase } from '@appbaseio/reactivesearch';
import { Link } from 'react-router-dom';
import Container from '../../styles/Container';
import MakeList from '../MakeList';
import SearchHeader from '../SearchHeader';
import ProvinceList from '../ProvinceList';

export default class BrowseMakeProvince extends Component {
	render() {
		return (
			<ReactiveBase
				app={process.env.REACT_APP_ELASTIC_APPNAME}
				credentials="user:QGeZgT15nSau"
				url={process.env.REACT_APP_ELASTIC_URL}
			>
				<h3 style={{ textAlign: 'center' }}>Car makes in South Africa</h3>
				<ReactiveComponent
					componentId="carBrowser" // a unique id we will refer to later
					defaultQuery={() => ({
						aggs: {
							makes: {
								terms: {
									field: 'make',
									size: 15,
									order: {
										_count: 'desc',
									},
								},
							},
						},
					})}
					render={({ aggregations, setQuery }) => (
						<MakeList aggregations={aggregations} setQuery={setQuery} />
					)}
				>
				</ReactiveComponent>
				<Link style={{ textAlign: 'center', display: 'block' }} to="/cars">All Makes</Link>
				<br/>
				<h3 style={{ textAlign: 'center' }}>Browse by Province</h3>

				<ReactiveComponent
					componentId="provinceBrowser" // a unique id we will refer to later
					defaultQuery={() => ({
						aggs: {
							provinces: {
								terms: {
									field: 'province',
									size: 150,
									order: {
										_key: 'asc',
									},
								},
							},
						},
					})}
					render={({ aggregations, setQuery }) => (
						<ProvinceList aggregations={aggregations} setQuery={setQuery} />
					)}
				>
				</ReactiveComponent>
			</ReactiveBase>
		);
	}
}
