import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { card } from './Flex';
import { queries } from './mediaQueries';
import theme from './theme';

const button = css`
	cursor: pointer;
	padding: 5px;
	margin: 5px;
	text-align: center;
	border-radius: 25px;
`;

export const ToggleFilters = styled.div`
	${button};
	${card};
	border-radius:0px;
	display: none;
	color: white;
	width: 200px;
	font-size: 1.1rem;
	padding: 10px;
	bottom: 1rem;
	position: fixed;
	left: calc(50% - 100px);
	margin: 0 auto;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	z-index: 4;
	background: ${theme.colors.primaryColor};

	&:hover {
		background: ${theme.colors.primaryColor};
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}

	${queries.large`
		display: block;
	`};
`;

const Button = styled.div`
	${button};
	background: ${theme.colors.lightGray};
	min-width: 70px;

	svg {
		margin-right: 4px;
	}

	&:hover {
		background: ${theme.colors.primaryColor};
		color: white;
	}
`;


export const CloseButton = styled.div`
    margin: -10px;
    color: #fff;
	padding: 10px;
    font-size: 1.1rem;
    border-radius: .4rem;
    background: ${theme.colors.secondaryColor};
    cursor: pointer;
`;

export const CloseButtonSvg = styled.svg`
	height: 15px;
    width: 15px;
    display: block;
    fill: rgb(72, 72, 72);
	svg {
		margin-right: 4px;
	}
`;
export const SearchButton = css`
	-moz-box-shadow: 0px 0px 9px 0px #3dc21b;
	-webkit-box-shadow: 0px 0px 9px 0px #3dc21b;
	box-shadow: 0px 0px 9px 0px #3dc21b;
	background-color:${theme.colors.primaryColor};
	-moz-border-radius:11px;
	-webkit-border-radius:11px;
	border-radius:11px;
	border:1px solid #18ab29;
	display:block;
	margin:0 auto;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
    padding:11px 31px;
    -webkit-appearance: none;
	text-decoration:none;
    text-shadow:0px 1px 0px #2f6627;
    ${queries.medium`
	padding-top: 10px;
	margin-bottom: 10px;
	margin-top: 20px;
    `}; 
`;



export default Button;
