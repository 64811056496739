import { css } from '@emotion/core';
import styled from '@emotion/styled';

import theme from './theme';

export const title = css`
	color: white;
	font-family: Pacifico, cursive;
	font-size: 2rem;
	text-align: center;
`;

const FooterBar = styled.section`
	background: ${theme.colors.secondaryColor};
	width: 100%;
    bottom: 0;
    position: absolute;
    text-align: center;
    height: 80px;
	z-index: 5;
`;

export const FooterContents = css`
list-style-type: none;
margin-top:10px;
color: white;
font-size: 14px;
font-family: ${theme.typography.fontFamily}
`;

export default FooterBar;
