import React, { Component } from 'react';
import SingleDropdownList from '@appbaseio/reactivesearch/lib/components/list/SingleDropdownList';
import Flex, { FlexChild } from '../styles/Flex';
import history from '../components/history';
import homeSearchBox from '../styles/SearchBox';
import { SearchButton } from '../styles/Button';
import SelectMake from './react-select/SelectMake';
import SelectModel from './react-select/SelectModel';

class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			makeValue: '',
			modelValue: '',
		};
	}

	doSearch = () => {
		if (this.state.makeValue !== '' && this.state.modelValue === '') {
			history.push(`/results?Make=["${this.state.makeValue}"]`);
		} else if (this.state.makeValue !== '' && this.state.modelValue !== '') {
			history.push(`/results?Make=["${this.state.makeValue}"]&Model=["${this.state.modelValue}"]`);
		} else {
			history.push('/results');
		}
	};

	updateMakeValue = (value) => {
		this.setState({
			makeValue: value,
		});
	};
	updateModelValue = (value) => {
		this.setState({
			modelValue: value,
		});
	};
	render() {
		return (
			<Flex
				alignCenter
				responsive
				justifyContent="space-between"
			>
				<FlexChild css={homeSearchBox}>
					<SelectMake onValueChange={this.updateMakeValue} />
					<SelectModel isEnabled={this.state.makeValue!=''} onValueChange={this.updateModelValue} />
				</FlexChild>
				<FlexChild>
					<input type="submit" css={SearchButton} value="Search" onClick={this.doSearch} />
				</FlexChild>
			</Flex>
		);
	}
}

export default SearchBox;
